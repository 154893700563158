<template>
  <div class="products">
    <v-row>
      <v-breadcrumbs :items="breadcrumbs" class="px-4">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-row class="pt-10">
      <v-col cols="12" class="py-0">
        <v-alert
          v-model="ui.alert.enable"
          :dismissible="ui.alert.dismissible"
          :icon="ui.alert.icon"
          :type="ui.alert.type"
          class="mb-0"
        >
          {{ ui.alert.message }}
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-card outlined
                :loading="ui.list.loading"
                :disabled="ui.list.disabled"
                class="rounded-lg overflow-hidden">
          <template slot="progress">
            <v-progress-linear
              color="blue-grey darken-3"
              height="6"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-row
            style="border-radius: 0"
            class="blue py-6 px-sm-9">
            <v-col cols="12" class="pb-0">
              <v-toolbar-title class="title white--text pl-0">
                {{ $vuetify.lang.t('$vuetify.product.products.list.ui.title') }}
              </v-toolbar-title>
              <p class="white--text mb-2">{{ $vuetify.lang.t('$vuetify.product.products.list.ui.subtitle') }}</p>
            </v-col>
          </v-row>
          <v-card-text class="px-0 pb-0">
            <div class="px-9">
              <div class="row">
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="datatable.search"
                    :disabled="ui.list.disabled"
                    :label="$vuetify.lang.t('$vuetify.global.search')"
                    ref="datatable_search"
                  >
                    <v-icon
                      slot="prepend"
                    >
                      mdi-magnify
                    </v-icon>
                  </v-text-field>
                </v-col>
                <v-col
                  class="justify-end pt-7 d-flex">
                </v-col>
              </div>
            </div>
            <v-data-table :headers="datatable.headers"
                          :items="datatable.items"
                          :search="datatable.search"
                          :items-per-page="datatable.items_per_page"
                          :server-items-length="datatable.total.items"
                          :options.sync="datatable.options"
                          class="datatable"
            >
              <template v-slot:item.image_path="{ item }">
                <v-img :src="item.image_path"  style="width: 60px; height: 60px" :alt="item.label"/>
              </template>
              <template v-slot:item.reference="{ item }">
                <span class="font-weight-bold">
                  {{ item.reference }}
                </span>
              </template>
              <template v-slot:item.reference_provider="{ item }">
                <span class="">
                  {{ item.reference_provider }}
                </span>
              </template>
              <template v-slot:item.label="{ item }">
                <span class="">
                  {{ item.label }}
                </span>
              </template>
              <template v-slot:item.manufacturer="{ item }">
                <span class="">
                  {{ item.manufacturer }}
                </span>
              </template>
              <template v-slot:item.is_service="{ item }">
                <v-icon v-if="item.is_service" color="green">mdi-check</v-icon>
                <v-icon v-else color="red">mdi-close</v-icon>
              </template>
              <template v-slot:item.is_solution="{ item }">
                <v-icon v-if="item.is_solution" color="green">mdi-check</v-icon>
                <v-icon v-else color="red">mdi-close</v-icon>
              </template>
              <template  v-slot:item.price_taxless="{ item }">
                <span class="font-weight-bold">
                  {{ helpers.toEuro(item.price_taxless) }}
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <span
                  v-bind:key="key"
                  v-for="(action, key) in _.toString(item.actions).split(',')">
                  <v-icon
                    tag="a"
                    v-if="action === 'see'"
                    small
                    class="mr-2"
                    v-on:click="$router.push({name:'GeneralProductsOne', params:{
                      id : item.id
                    }})"
                  >
                    mdi-eye
                  </v-icon>
                   <v-icon
                     tag="a"
                     v-if="action === 'edit'"
                     small
                     class="mr-2"
                     v-on:click="$router.push({name:'GeneralProductsEdit', params:{
                      id : item.id
                    }})"
                   >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    tag="a"
                    v-if="action === 'duplicate'"
                    small
                    class="mr-2"
                    v-on:click="$router.push({name:'GeneralProductsDuplicate', params:{
                      id : item.id
                    }})"
                  >
                    mdi-content-duplicate
                  </v-icon>
                  <v-dialog
                    v-model="datatable.deletes[item.id]"
                    persistent
                    v-if="action === 'delete'"
                    max-width="320"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        :disabled="item.part_bundle > 0"
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <dialog-delete
                      :item="item"
                      :on-confirm="()=> { deleteProduct(item); datatable.deletes[item.id] = false }"
                      :on-cancel="()=>{datatable.deletes[item.id] = false}"
                    ></dialog-delete>
                  </v-dialog>
                </span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-fab-transition>
      <div class="v-btn-right-bottom">
        <v-menu
          min-width="fit-content"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          :nudge-left="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="blue"
              fab
              large
              dark
              class="ml-2"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn
                text
                @click="menu = false"
                :to="{name:'GeneralProductsAdd', params: {type : 'simple'}}">
                Simple
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                text
                @click="menu = false"
                :to="{name:'GeneralProductsAdd', params: {type : 'bundle'}}">
                Bundle
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-fab-transition>
  </div>
</template>
<script>

import DialogDelete from './dialog/Delete'

export default {
  components: {
    'dialog-delete': DialogDelete
  },
  data: () => ({
    add_dialog: false,
    menu: false,
    ui: {
      list: {
        loading: true,
        disabled: true
      },
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    breadcrumbs: [],
    datatable: {
      search: '',
      search_interval: null,
      headers: [],
      total: {
        items: 0,
        pages: 0
      },
      options: {},
      current_page: 1,
      items_per_page: 5,
      items: [],
      edit: {},
      deletes: {}
    }
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    async fetchProducts () {
      this.ui.list.disabled = true
      this.ui.list.loading = true

      try {
        const response = await this.axios.get('/general/products/list', {
          params: {
            page: this.datatable.options.page,
            search: this.datatable.search,
            limit: this.datatable.options.itemsPerPage,
            sort: this.datatable.options.sortDesc.reduce((result, field, index) => {
              result[this.datatable.options.sortBy[index]] = field
              return result
            }, {})
          },
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })

        this.datatable.items = this._.map(response.data.data, (element) => {
          element.actions = ['see', 'edit', 'duplicate', 'delete']
          return element
        })
        this.datatable.total.pages = response.data.totalPages
        this.datatable.total.items = response.data.totalItems
        this.datatable.current_page = response.data.currentPage
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.products.list.notification.load'))
        this.datatable.items = []
      } finally {
        this.ui.list.disabled = false
        this.ui.list.loading = false
      }
    },
    async deleteProduct (item) {
      this.ui.list.disabled = true
      this.ui.list.loading = true

      try {
        await this.axios.get('/general/products/delete/' + item.id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })
        await this.fetchProducts()
        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.product.products.delete.notification.deleted'))
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 405 :
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.products.delete.notification.fail'))
              break
            case 302 :
              this.alert('warning', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.products.delete.notification.used'))
              break
            case 404 :
              this.alert('info', 'mdi-information', this.$vuetify.lang.t('$vuetify.product.products.delete.notification.not_found'))
              break
            default :
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.product.products.delete.notification.unknown'))
              break
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.list.disabled = false
        this.ui.list.loading = false
      }
    }
  },
  mounted () {
    this.fetchProducts()
    // restore alerts
    if (!this._.isUndefined(this.$router.currentRoute.params.alert)) {
      this.ui.alert = this.$router.currentRoute.params.alert
    }
  },
  watch: {
    'datatable.options': function (data) {
      this.fetchProducts()
    },
    'datatable.search': function (data) {
      if (!this._.isNull(this.datatable.search_interval)) clearTimeout(this.datatable.search_interval)

      this.datatable.search_interval = setTimeout(async () => {
        this.datatable.search_interval = null
        await this.fetchProducts()
        this.$refs.datatable_search.focus()
      }, 500)
    }
  },
  created () {
    // Define translations
    this.breadcrumbs = [
      {
        text: this.$vuetify.lang.t('$vuetify.home.name'),
        disabled: false,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.general.name'),
        disabled: true,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.product.name'),
        disabled: true,
        to: { name: 'GeneralProductsList' }
      }
    ]
    this.datatable.headers = [
      {
        value: 'image_path',
        text: this.$vuetify.lang.t('$vuetify.product.products.list.ui.datatable.image'),
        sortable: false
      },
      {
        value: 'reference',
        text: this.$vuetify.lang.t('$vuetify.product.products.list.ui.datatable.reference')
      },
      {
        value: 'reference_provider',
        text: this.$vuetify.lang.t('$vuetify.product.products.list.ui.datatable.reference_provider')
      },
      {
        value: 'label',
        text: this.$vuetify.lang.t('$vuetify.product.products.list.ui.datatable.label')
      },
      {
        value: 'manufacturer',
        text: this.$vuetify.lang.t('$vuetify.product.products.list.ui.datatable.manufacturer')
      },
      {
        value: 'is_service',
        text: this.$vuetify.lang.t('$vuetify.product.products.list.ui.datatable.is_service')
      },
      {
        value: 'is_solution',
        text: this.$vuetify.lang.t('$vuetify.product.products.list.ui.datatable.is_solution')
      },
      {
        value: 'price_taxless',
        align: 'right',
        text: this.$vuetify.lang.t('$vuetify.product.products.list.ui.datatable.price_taxless')
      },
      {
        text: this.$vuetify.lang.t('$vuetify.global.actions'),
        align: 'right',
        value: 'actions',
        sortable: false
      }
    ]
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

::v-deep {
  .datatable {
    tr {
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        th:first-child, td:first-child {
          padding-left: 36px;
        }

        th:last-child, td:last-child {
          padding-right: 36px;
        }
      }

    }
  }
}
</style>
